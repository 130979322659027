import React from "react";
import styles from "./Footer.module.scss";
import Link from "next/link";
import Splitter from "./Splitter";
import FancyImage from "./FancyImage";
import { isMobile } from "react-device-detect";
import MaralImage from "../assets/maral_small.png";
import { NavigationSection } from "../pages/_app";

interface Props {
  navigation?: NavigationSection[];
}

const Footer: React.FC<Props> = (props) => {
  const [mouseXY, setMouseXY] = React.useState({
    x: 0,
    y: 0,
  });
  const [mouseIn, setMouseIn] = React.useState(false);
  const footerRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (!isMobile) {
      const footerRefCurrent = footerRef.current;

      const handleMousePosition = (event: MouseEvent) => {
        setMouseXY({
          x: event.clientX,
          y: event.clientY,
        });
      };

      const handleMouseIn = () => {
        setMouseIn(true);
      };

      const handleMouseOut = () => {
        setMouseIn(false);
      };

      if (footerRefCurrent) {
        footerRefCurrent.addEventListener("mousemove", handleMousePosition);
        footerRefCurrent.addEventListener("mouseenter", handleMouseIn);
        footerRefCurrent.addEventListener("mouseleave", handleMouseOut);
      }

      return () => {
        footerRefCurrent?.removeEventListener("mousemove", handleMousePosition);
        footerRefCurrent?.removeEventListener("mouseenter", handleMouseIn);
        footerRefCurrent?.removeEventListener("mouseleave", handleMouseOut);
      };
    }
  }, []);

  return (
    <footer
      className={`${styles["footer-container"]} content-wrapper`}
      ref={footerRef}
    >
      <div
        className={`${styles["footer-mouse-follower"]} ${
          mouseIn ? styles["active"] : ""
        }`}
        style={{ top: mouseXY.y, left: mouseXY.x }}
      >
        <FancyImage
          shouldFade
          alt="Maral the astronaut following the cursor."
          src={MaralImage}
        />
      </div>
      <div className="content-container">
        <div className="_4-col">
          <div className="col">
            <p>
              Our mission at Kayhan Space is Making Spaceflight Safer™ by
              building next-generation autonomous spaceflight safety
              capabilities.
            </p>
          </div>
          {props.navigation
            ? props.navigation.map((menuItem, idx) => {
                if (!menuItem.FooterOnly) {
                  return (
                    <div className="col" key={`footer-nav-1-${idx}`}>
                      <h4 className={styles["footer-header"]}>
                        {menuItem.Title}
                      </h4>
                      <ul className={styles["footer-links"]}>
                        {menuItem.NavItem.length > 0
                          ? menuItem.NavItem.map((link, i) => {
                              //const isDynamic = link.IsDynamicPage ? '/base' : '';
                              //const href = menuItem.Slug ? `${isDynamic}${menuItem.Slug}${link.Slug}` : `${isDynamic}${link.Slug}`;
                              const href = menuItem.Slug
                                ? `${menuItem.Slug}${link.Slug}`
                                : `${link.Slug}`;
                              if (href) {
                                return (
                                  <li key={`footer-nav-1-${idx}-${i}`}>
                                    <Link href={href} className="link">
                                      {link.Title}
                                    </Link>
                                  </li>
                                );
                              }
                              return null;
                            })
                          : null}
                      </ul>
                    </div>
                  );
                }
                return null;
              })
            : null}
        </div>
        <Splitter classNames={styles["footer-splitter"]} />
        <div className="_4-col">
          {props.navigation
            ? props.navigation.map((menuItem, idx) => {
                if (menuItem.FooterOnly) {
                  return (
                    <React.Fragment key={`empty-col-nav-2-${idx}`}>
                      {menuItem.NavItem.length < 4 ? (
                        <div className="col empty" />
                      ) : null}
                      {menuItem.NavItem.length > 0
                        ? menuItem.NavItem.map((link, i) => {
                            //const isDynamic = link.IsDynamicPage ? '/base' : '';
                            //const href = menuItem.Slug ? `${isDynamic}${menuItem.Slug}${link.Slug}` : `${isDynamic}${link.Slug}`;
                            const href = menuItem.Slug
                              ? `${menuItem.Slug}${link.Slug}`
                              : `${link.Slug}`;
                            const classNames =
                              menuItem.NavItem.length - 1 === i
                                ? "col mobile-no-padding-bottom"
                                : "col mobile-1rem-padding-bottom";
                            if (href) {
                              return (
                                <div
                                  className={classNames}
                                  key={`footer-nav-2-${idx}-${i}`}
                                >
                                  <ul className={styles["footer-links"]}>
                                    <li>
                                      <Link className="link" href={href}>
                                        {link.Title}
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              );
                            }
                            return null;
                          })
                        : null}
                    </React.Fragment>
                  );
                }
                return null;
              })
            : null}
        </div>
        <Splitter classNames={styles["footer-splitter"]} />
        <div className="_4-col">
          <div className="col">
            © {new Date().getUTCFullYear()} Kayhan Space Corp.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
